interface GenericListingItem
{
    name: string;
    url: string;
};

const autocomplete_timeout = 250;

class AutocompleteField
{
    private input: HTMLInputElement;
    private list: HTMLDivElement | null = null;
    private url: string;
    private currentTimeout: NodeJS.Timeout | null = null;

    private async autoResponse(data: Response)
    {
        const dataObj = (await data.json()) as GenericListingItem[];
        if (dataObj == null || dataObj.length === 0)
        {
            return;
        }

        if (this.list !== null)
        {
            this.list.remove();
        }

        this.list = document.createElement("div");
        this.list.classList.add("dropdown-menu");
        this.list.classList.add("dropdown-autocomplete");

        for (const item of dataObj)
        {
            const link = document.createElement("a");
            link.textContent = item.name;
            link.href = item.url;
            link.classList.add("dropdown-item");

            this.list.appendChild(link);
        }

        this.input.after(this.list);
    }

    private fetchData()
    {
        if (this.list !== null)
        {
            this.list.remove();
            this.list = null;
        }

        if (this.input.value === "")
        {
            return;
        }

        const term = encodeURIComponent(this.input.value);
        const termUrl = this.url.replace("{term}", term);

        fetch(termUrl, {
            method: "GET"
        }).then((data) => this.autoResponse(data));
    }

    private contentChanged()
    {
        if (this.currentTimeout != null)
        {
            clearTimeout(this.currentTimeout);
        }

        this.currentTimeout = setTimeout(() => this.fetchData(), autocomplete_timeout);
    }

    public static create(fieldElement: HTMLInputElement) : AutocompleteField | null
    {
        if (fieldElement.dataset.autocompleteUrl === undefined)
        {
            return null;
        }

        return new AutocompleteField(fieldElement, fieldElement.dataset.autocompleteUrl);
    }

    private constructor(fieldElement: HTMLInputElement, autoUrl: string)
    {
        this.url = decodeURIComponent(autoUrl);
        this.input = fieldElement;
        this.input.addEventListener("keyup", () => this.contentChanged());
    }
}

const fields = [];
const autoElements = document.querySelectorAll("[data-autocomplete=generic-term]");
for (let i = 0; i < autoElements.length; i++)
{
    const field = AutocompleteField.create(autoElements[i] as HTMLInputElement);

    if (field !== null)
    {
        fields.push(field);
    }
}