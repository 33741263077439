let initialZoom = false;


export class MarkerWithPopup extends google.maps.Marker
{
    html: string | undefined;
}

export function FindMapCenterAndZoom(map: google.maps.Map | null, placeMarkers: Array<google.maps.Marker>): void
{
    if (map == null || placeMarkers.length === 0)
    {
        return;
    }

    const bounds = new google.maps.LatLngBounds();
    placeMarkers.forEach((value: google.maps.Marker) =>
    {
        const position = value.getPosition();
        if (position != null)
        {
            bounds.extend(position);
        }
    });

    google.maps.event.addListener(map, "zoom_changed", () =>
    {
        const zoomChangeBoundsListener = google.maps.event.addListener(map, "bounds_changed", function (this: google.maps.Map)
        {
            if (this.getZoom() > 16 && initialZoom)
            {
                // Change max/min zoom here
                this.setZoom(16);
                initialZoom = false;
            }
            google.maps.event.removeListener(zoomChangeBoundsListener);
        });
    });

    initialZoom = true;
    map.fitBounds(bounds);
}