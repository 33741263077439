import 'bxslider/src/js/jquery.bxslider';
import 'jquery-colorbox/jquery.colorbox';
import * as MapHelpers from './helpers/_maps';

const targetWidth = 640;
const slideMargin = 20;

let mapElement: HTMLElement;
let map: google.maps.Map;

let slider: bxSlider;

const placeMarkers = new Array<google.maps.Marker>();
const sections = new Array<HTMLElement>();
const sectionNavs = new Array<HTMLAnchorElement>();

function determineSlideWidth(): number
{
    const wrap = document.getElementById("property-slider") as HTMLElement;
    if (!wrap)
    {
        return 0;
    }

    const count = Math.round(wrap.clientWidth / targetWidth);
    const width = Math.floor((wrap.clientWidth - (slideMargin * (count - 1))) / count);
    return width;
}

function generateOptions(currentSlide = 0)
{
    return {
        slideMargin: slideMargin,
        minSlides: 1,
        maxSlides: 4,
        moveSlides: 1,
        slideWidth: determineSlideWidth(),
        auto: true,
        autoStart: true,
        pause: 4000,
        pager: false,
        startSlide: currentSlide,
        prevText: "\uf100",
        nextText: "\uf101"
    };
}

function resizeSlider()
{
    if (!slider)
    {
        return;
    }

    const currentSlide = slider.getCurrentSlide();
    slider.destroySlider();

    slider = jQuery("#property-slider .bxslider").bxSlider(generateOptions(currentSlide));
}


function toggleSection(ev: Event)
{
    ev.preventDefault();

    if (!sectionNavs)
    {
        return;
    }

    const source = ev.currentTarget as HTMLAnchorElement;
    const link = source.href.substring(source.href.lastIndexOf("#") + 1);

    for (let i = 0; i < sectionNavs.length; i++)
    {
        const parent = sectionNavs[i].parentElement;
        if (!parent)
        {
            continue;
        }

        parent.classList.remove("active");
    }

    for (let i = 0; i < sections.length; i++)
    {
        const section = sections[i];

        if (section.id === link)
        {
            section.classList.add("show");
        }
        else
        {
            section.classList.remove("show");
        }
    }

    if (source.parentElement != null)
    {
        source.parentElement.classList.add("active");
    }

    if (link === "property-map")
    {
        // trigger a redraw which is necessary for a map which was loaded invisibly
        google.maps.event.trigger(map, "resize");
        MapHelpers.FindMapCenterAndZoom(map, placeMarkers);

        const sv = map.getStreetView();
        if (source.id === "toggle-sv")
        {
            const povLat = mapElement.dataset["povLat"],
                povLng = mapElement.dataset["povLng"];

            if (povLat && povLng)
            {
                const povLatNum = parseFloat(povLat), povLngNum = parseFloat(povLng);
                if (!isNaN(povLatNum) && !isNaN(povLngNum))
                {
                    const position = new google.maps.LatLng(povLatNum, povLngNum);
                    sv.setPosition(position);
                }
            }

            const povHdg = mapElement.dataset["povHdg"],
                povPth = mapElement.dataset["povPth"];

            if (povHdg && povPth)
            {
                const povHdgNum = parseFloat(povHdg), povPthNum = parseFloat(povPth);
                if (!isNaN(povHdgNum) && !isNaN(povPthNum))
                {
                    sv.setPov({
                        heading: povHdgNum,
                        pitch: povPthNum
                    });
                }
            }

            const povZom = mapElement.dataset["povZom"];
            if (povZom)
            {
                const povZomNum = parseFloat(povZom);
                if (!isNaN(povZomNum))
                {
                    sv.setZoom(povZomNum);
                }
            }

            sv.setVisible(true);
        }
        else
        {
            sv.setVisible(false);
        }
    }
}

export function Initialize()
{
    slider = jQuery("#property-slider .bxslider").bxSlider(generateOptions(jQuery("#property-slider .slide").length - 1));
    window.addEventListener("resize", resizeSlider);

    const elem = document.getElementById("property-map");
    if (elem !== null)
    {
        mapElement = elem;

        map = new google.maps.Map(mapElement, {
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

        const titleElements = document.getElementsByTagName("h1");
        const title = titleElements.length > 0
            ? (titleElements[0] as HTMLHeadingElement).innerText
            : "";


        const mapLat = mapElement.dataset["mapLat"],
            mapLng = mapElement.dataset["mapLng"];

        if (mapLat != null && mapLng != null)
        {
            const point = new google.maps.LatLng(parseFloat(mapLat), parseFloat(mapLng));
            const marker = new MapHelpers.MarkerWithPopup({
                map: map,
                position: point,
                title: title
            });
            placeMarkers.push(marker);
        }

        MapHelpers.FindMapCenterAndZoom(map, placeMarkers);
    }

    const toggleSections = document.getElementById("toggle-sections");
    if (toggleSections)
    {
        for (let i = 0; i < toggleSections.children.length; i++)
        {
            const child = toggleSections.children[i] as HTMLElement;
            sections.push(child);
        }
    }

    const toggleLinks = document.querySelectorAll("#search-navigation-options a");
    for (let i = 0; i < toggleLinks.length; i++)
    {
        const link = toggleLinks[i] as HTMLAnchorElement;
        link.addEventListener("click", toggleSection);
        sectionNavs.push(link);
    }

    jQuery("#property-slider .slide").colorbox({
        maxWidth: "85%",
        maxHeight: "85%",
        rel: "slide",
        previous: "<i class=\"la la-chevron-left\"></i>",
        next: "<i class=\"la la-chevron-right\"></i>",
        close: "<i class=\"la la-times\"></i>",
        href: function ()
        {
            return jQuery(this).data("url");
        }
    });
}