import * as $ from 'jquery';

const targetWidth = 370;
const slideMargin = 20;

let slider: bxSlider;

function determineSlideWidth(): number
{
    const wrap = document.getElementById("featured-properties") as HTMLElement;
    if (wrap == null)
    {
        return 0;
    }

    const count = Math.round(wrap.clientWidth / targetWidth);
    const width = Math.floor((wrap.clientWidth - (slideMargin * (count - 1))) / count);
    return width;
}

function generateOptions(currentSlide: number = 0): bxSliderOptions
{
    return {
        slideMargin: slideMargin,
        minSlides: 1,
        maxSlides: 4,
        moveSlides: 4,
        slideWidth: determineSlideWidth(),
        auto: true,
        autoStart: true,
        pause: 4000,
        pager: true,
        startSlide: currentSlide,
        controls: false
    };
}

function resizeSlider()
{
    if (slider == null)
    {
        return;
    }

    const currentSlide = slider.getCurrentSlide();
    slider.destroySlider();

    slider = $("#featured-properties .bxslider").bxSlider(generateOptions(currentSlide));
}


export function Initialize()
{
    if (typeof ($.fn.bxSlider) !== "undefined")
    {
        slider = $("#featured-properties .bxslider").bxSlider(generateOptions());
        window.addEventListener("resize", resizeSlider);
    }
}