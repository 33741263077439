"use strict";

import * as MapHelpers from './helpers/_maps';

let mapElement: HTMLElement;
let listElement: HTMLElement;
let searchPageNav: HTMLUListElement;
let sortingOptions: HTMLSelectElement;

let map: google.maps.Map;
let infoWindow: google.maps.InfoWindow;

const placeMarkers = new Array<google.maps.Marker>();

function toggleToMap(ev: Event)
{
    ev.preventDefault();
    listElement.classList.remove("show");
    mapElement.classList.add("show");

    const links = document.querySelectorAll("#search-navigation a");
    for (let i = 0; i < links.length; i++)
    {
        const link = links[i] as HTMLAnchorElement;
        const linkParent = link.parentElement;

        if (!linkParent)
        {
            continue;
        }

        if (link.href.indexOf(`#${mapElement.id}`) !== -1)
        {
            linkParent.classList.add("active");
        }
        else
        {
            linkParent.classList.remove("active");
        }
    }

    // trigger a redraw which is necessary for a map which was loaded invisibly
    google.maps.event.trigger(map, "resize");
    MapHelpers.FindMapCenterAndZoom(map, placeMarkers);
}

function toggleToListing(ev: Event)
{
    ev.preventDefault();
    mapElement.classList.remove("show");
    listElement.classList.add("show");

    const links = document.querySelectorAll("#search-navigation a");
    for (let i = 0; i < links.length; i++)
    {
        const link = links[i] as HTMLAnchorElement;
        const linkParent = link.parentElement;

        if (!linkParent)
        {
            continue;
        }

        if (link.href.indexOf(`#${listElement.id}`) !== -1)
        {
            linkParent.classList.add("active");
        }
        else
        {
            linkParent.classList.remove("active");
        }
    }
}

function toggleToSearchPage(ev: Event)
{
    ev.preventDefault();
    const targetLink = (ev.currentTarget as HTMLAnchorElement).href;
    const targetId = targetLink.substring(targetLink.lastIndexOf("#") + 1);
    const pages = listElement.getElementsByClassName("search-list-page");
    for (let i = 0; i < pages.length; i++)
    {
        if (pages[i].id === targetId)
        {
            pages[i].classList.add("active");
        }
        else
        {
            pages[i].classList.remove("active");
        }
    }

    const links = searchPageNav.getElementsByTagName("a");
    for (let i = 0; i < links.length; i++)
    {
        const linkParent = links[i].parentElement;

        if (!linkParent)
        {
            continue;
        }

        if (links[i].href === targetLink)
        {
            linkParent.classList.add("active");
        }
        else
        {
            linkParent.classList.remove("active");
        }
    }
}

function updateSorting()
{
    document.cookie = `OrderOption=${sortingOptions.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    location.reload();
}

export function Initialize(mapId: string, listId: string)
{
    sortingOptions = document.getElementById("sort_order") as HTMLSelectElement;
    sortingOptions.addEventListener("change", updateSorting);

    const listToggle = document.getElementById("toggle-list") as HTMLAnchorElement;
    listToggle.addEventListener("click", toggleToListing);

    const mapToggle = document.getElementById("toggle-map") as HTMLAnchorElement;
    mapToggle.addEventListener("click", toggleToMap);

    const mapElem = document.getElementById(mapId);
    const listElem = document.getElementById(listId);

    if (mapElem === null || listElem === null)
    {
        return;
    }

    mapElement = mapElem;
    listElement = listElem;

    map = new google.maps.Map(mapElement, {
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    const searchPages = listElement.getElementsByClassName("search-list-page");
    searchPageNav = document.createElement("ul");
    searchPageNav.className = "pagination";
    for (let i = 0; i < searchPages.length; i++)
    {
        const pageListItem = document.createElement("li");
        pageListItem.classList.add("page-item");
        if (i === 0)
        {
            pageListItem.classList.add("active");
        }

        const pageLink = document.createElement("a");
        pageLink.className = "page-link";
        pageLink.href = `#${searchPages[i].id}`;
        pageLink.innerText = (i + 1).toFixed(0);
        pageLink.addEventListener("click", toggleToSearchPage);

        pageListItem.appendChild(pageLink);
        searchPageNav.appendChild(pageListItem);
    }

    listElement.appendChild(searchPageNav);

    const listItems = document.getElementsByClassName("property-item");
    for (let i = 0; i < listItems.length; i++)
    {
        const listItem = listItems[i] as HTMLAnchorElement;

        const lat = listItem.dataset["lat"],
            lng = listItem.dataset["lng"];

        if (!lat || !lng)
        {
            continue;
        }

        const point = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        const title = (listItem.getElementsByTagName("h3")[0] as HTMLHeadingElement).innerText;
        const desc = (listItem.getElementsByClassName("item-attributes")[0] as HTMLDivElement).outerHTML;
        const link = listItem.href;

        const marker = new MapHelpers.MarkerWithPopup({
            map: map,
            position: point,
            title: title
        });
        marker.html = `<a class="property-item" href="${link}"><h3>${title}</h3>${desc}</div>`;

        google.maps.event.addListener(marker, "click", function (this: MapHelpers.MarkerWithPopup)
        {
            if (infoWindow)
            {
                infoWindow.close();
            }

            infoWindow = new google.maps.InfoWindow({
                content: this.html
            });

            infoWindow.open(map, this);
        });

        placeMarkers.push(marker);
    }

    MapHelpers.FindMapCenterAndZoom(map, placeMarkers);
}