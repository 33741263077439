import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'jssocials';

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'lazysizes';

import './validation/mustbetrue';
import '@dbs/style-switcher';

import './components/CKReadMore';
import './components/MapOffices';
import './components/GenericAutocomplete';
import './components/BotForm';

import { InitialiseForElement as AjaxFormInitialise } from './components/PartialHost';
import { Initialize as SearchInitialize } from './pages/search';
import { Initialize as PropertyInitialize } from './pages/property';
import { Initialize as FeaturedSliderInitialize } from './pages/featured-slider';
import { Initialise as RecaptchaFormInitialise } from './components/RecaptchaForm';

document.body.classList.remove("no-js");
document.body.classList.add("js");

$(function ()
{
    if ($("#home-parallax").length === 1)
    {
        $(window).scroll(() =>
        {
            const scrollTop = $(window).scrollTop();
            const parallaxHeight = $("#home-parallax").height();

            if (!scrollTop || !parallaxHeight)
            {
                return;
            }

            const scrollPos = scrollTop - ($("#home-parallax").position().top + 60) + parallaxHeight + 30;
            $("#home-parallax-photo").css("background-position", `center ${scrollPos}px`);
        });
    }

    $("#toggle-navigation").click(function (ev)
    {
        ev.preventDefault();
        $("#page-nav").toggleClass("visible");
        $(".nav-header").toggleClass("expanded");
        $(this).toggleClass("is-active");
    });

    $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event)
    {
        event.preventDefault();
        event.stopPropagation();

        const parent = $(this).parent(".dropdown");
        const prevStatus = parent.hasClass("show");

        parent.toggleClass("show");
        $(this).siblings(".dropdown-menu").toggleClass("show");

        if (!prevStatus)
        {
            const root = $(this).parentsUntil("nav", ".navbar-nav");
            if (root.length !== 1)
            {
                return;
            }

            const rootElem = root[0];
            const other = $(rootElem).find(".dropdown.show");

            other.each((_idx, elem) =>
            {
                const isParentOrElement = $(elem).has(parent[0]).length !== 0 || parent[0] === elem;
                if (!isParentOrElement)
                {
                    $(elem).removeClass("show");
                    $(elem).find(".dropdown-menu").removeClass("show");
                }
            });
        }
    });

    $("[data-toggle=\"popover\"]").popover();

    const forms = document.querySelectorAll("form[data-ajax]");
    for (let i = 0; i < forms.length; i++)
    {
        if ((forms[i] as HTMLFormElement).dataset.recaptcha)
        {
            RecaptchaFormInitialise(forms[i] as HTMLFormElement);
        }
        else
        {
            AjaxFormInitialise(forms[i] as HTMLFormElement);
        }
    }

    const socialLinks = document.getElementsByClassName("item-social");
    for (let i = 0; i < socialLinks.length; i++)
    {
        const socialLink = socialLinks[i] as HTMLDivElement;

        const config = {
            showLabel: false,
            showCount: false,
            url: socialLink.dataset["target"],
            text: socialLink.dataset["desc"],
            shares: [
                {
                    share: "email",
                    logo: "la la-at"
                },
                {
                    share: "twitter",
                    logo: "la la-twitter"
                },
                {
                    share: "facebook",
                    logo: "la la-facebook-f"
                }
            ]
        } as jsSocials.IConfig;

        $(socialLink).jsSocials(config);
    }

    if (document.getElementsByClassName("property-list").length > 0)
    {
        SearchInitialize("search-map", "search-list");
    }
    else if (document.getElementById("property-slider"))
    {
        PropertyInitialize();
    }

    FeaturedSliderInitialize();

    window.InitializeMapView();
});